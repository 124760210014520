import { Injectable } from '@angular/core';
import { createReducer, on } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { difference, omit, uniq, uniqBy } from 'lodash';
import moment from 'moment';

import {
  CollectedType,
  Stats,
  StatsAccommodationFilterOption,
  StatsChartState,
  StatsPreset,
  StatsRateplanFilterOption,
  StatsTotals,
} from '../../models';
import { StatsFiltersOptions } from '../../models/objects/stats-filters-options';
import { StatsHelperService } from '../../services';
import { StatsTotalsHelperService } from '../../services/stats-totals-helper.service';

import * as fromActions from './actions';
import * as fromState from './state';

@Injectable()
export class StatsProductionReducer {
  constructor(
    private translateService: TranslateService,
    private statsHelper: StatsHelperService,
    private statsTotalsHelper: StatsTotalsHelperService,
  ) {}

  createReducer() {
    return createReducer(
      fromState.initialState,

      on(fromActions.clearAllRows, (state) =>
        fromState.featureAdapter.removeAll({
          ...state,
          stats: {
            dictionary: {},
            values: [],
            totals: null,
          },
        }),
      ),
      on(fromActions.clearTotals, (state) => ({
        ...state,
        totalsRow: null,
      })),
      on(fromActions.clearChart, (state) => ({
        ...state,
        chart: {
          chartTypeSelected: 'gross_amount' as CollectedType,
          cartesian: [],
          histogramMulti: [],
          histogramSingle: [],
        },
      })),
      on(fromActions.setSettingsSuccess, (state, { settings }) => {
        return {
          ...state,
          settings,
        };
      }),

      on(
        fromActions.setSelectedColumnsSuccess,
        (state, { selectedColumns, settings }) => {
          return {
            ...state,
            selectedColumns,
            settings,
          };
        },
      ),
      on(fromActions.buildChart, (state, { translations }) => {
        const {
          stats: statsResponse,
          filtersHeaderValues: { type, partitioning, compare },
          chart: { chartTypeSelected },
        } = state;

        let chart: StatsChartState = {
          chartTypeSelected,
        };
        if (type === 'date') {
          chart = {
            ...chart,
            cartesian: this.statsHelper.onbuildCartesianChartLine({
              chartTypeSelected,
              statsResponse,
              partitioning,
              translations,
              compare,
            }),
          };
        }
        if (type !== 'date' && compare) {
          const histogramMulti =
            this.statsHelper.onbuildHistogramMultiChartLine({
              chartTypeSelected,
              statsResponse,
              partitioning,
              translations,
              compare,
            });
          chart = {
            ...chart,
            histogramMulti,
            barNumber:
              uniqBy(
                histogramMulti.reduce(
                  (acc, curr) => [...acc, ...curr.series],
                  [],
                ),
                'name',
              ).length * histogramMulti.length,
          };
        }
        if (type !== 'date' && !compare) {
          const histogramSingle =
            this.statsHelper.onBuildHistogramSingleChartLiene({
              chartTypeSelected,
              statsResponse,
              compare,
            });
          chart = {
            ...chart,
            histogramSingle,
            barNumber: histogramSingle.length,
          };
        }
        return {
          ...state,
          chart,
        };
      }),
      on(
        fromActions.setPropertiesSelected,
        (state, { propertiesSelectedIds }) => {
          return {
            ...state,
            propertiesSelectedIds,
          };
        },
      ),

      on(fromActions.setProperties, (state, { properties }) => {
        return {
          ...state,
          properties,
        };
      }),

      on(
        fromActions.loadFiltersSuccess,
        (state, { filters: filtersOptionsByProperties }) => {
          const { propertiesAddons } = filtersOptionsByProperties;
          const addonsCategories =
            this.statsHelper.getAddonCategories(propertiesAddons);
          return {
            ...state,
            error: null,
            filtersOptionsByProperties,
            addonsCategories,
          };
        },
      ),
      on(fromActions.loadFiltersFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),

      on(fromActions.LoadAccommodationTableauNumberRequest, (state) => ({
        ...state,
        isLoadingTableauNumbers: true,
        error: null,
      })),
      on(
        fromActions.LoadAccommodationTableauNumberSuccess,
        (state, tableauNumbersData) => {
          const { tableauNumbers, tableauNumbersToLoad } = tableauNumbersData;

          let newState = { ...state };

          const { child: tableauNamberChild, parent: tableauNumberParent } =
            tableauNumbersToLoad[0];

          const { dictionary: newDictionay } = tableauNumbers[0];

          const indexParent =
            tableauNumberParent.key + ':' + tableauNamberChild.key;
          const newValues = tableauNumbers[0].values.map((value) => {
            return {
              ...value,
              index: indexParent + ':' + value.key,
              label: newDictionay[value.key].label,
              status: newDictionay[value.key].status,
            };
          });

          const { dictionary: oldDictionary, values: oldValues } = state.stats;
          const dictionary = {
            ...oldDictionary,
            ...newDictionay,
          };
          const values: Stats[] = oldValues.map((value) => {
            const { key, children } = value;
            let newValue = value;
            if (key === tableauNumberParent.key) {
              const newChildren = children?.map((_child) => {
                let newChild = _child;
                if (tableauNamberChild.key === _child.key) {
                  newChild = {
                    ...newChild,
                    children: newValues,
                  };
                }
                return newChild;
              });
              newValue = {
                ...newValue,
                children: newChildren,
              };
            }
            return newValue;
          });

          newState = {
            ...newState,
            isLoadingTableauNumbers: false,
            error: null,
            stats: {
              ...state.stats,
              dictionary,
              values,
            },
          };

          let entities = newState.ids.map((id) => newState.entities[id]);

          // Rimuovo i le chiavi dei numeri tableau che devo sostiuire
          entities = entities.filter(
            ({ parentKey, isTableauNumber, rootParentIndex }) =>
              !(
                isTableauNumber &&
                parentKey === tableauNamberChild.key &&
                rootParentIndex === tableauNumberParent.key
              ),
          );

          // Aggiungo i nuovi valori tableau della camera con le rispettive chiavi
          entities = entities.reduce((newEntities, entity) => {
            newEntities = [...newEntities, entity];

            if (entity.index === indexParent) {
              newEntities = [...newEntities, ...newValues];
            }

            return newEntities;
          }, []);

          return fromState.featureAdapter.setAll(entities, newState);
        },
      ),
      on(
        fromActions.LoadAccommodationTableauNumberFailure,
        (state, { error }) => ({
          ...state,
          isLoadingTableauNumbers: false,
          error,
        }),
      ),

      on(fromActions.loadSplittedRequest, (state) => ({
        ...state,
        splitLoading: true,
        error: null,
      })),

      on(fromActions.loadSplittedSuccess, (state) => {
        return {
          ...state,
          splitLoading: false,
          error: null,
        };
      }),

      on(fromActions.loadSplittedFailure, (state) => ({
        ...state,
        splitLoading: false,
        error: null,
      })),
      on(fromActions.loadRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(
        fromActions.loadSuccess,
        (state, { stats, translations: { total } }) => {
          const statsList = this.statsHelper.flatDataArray(
            stats,
            total,
            null,
            null,
          );

          const {
            chart: { chartTypeSelected },
          } = state;

          const columnsLoaded = uniq([
            ...Object.keys(statsList[0].collected),
            chartTypeSelected,
          ]) as CollectedType[];
          return fromState.featureAdapter.setAll(statsList, {
            ...state,
            isLoading: false,
            error: null,
            stats,
            tableauNumbers: {
              accommodationRowsInViewport: [],
              accommodationRowsLoaded: [],
              accommodationRowsToLoad: [],
            },
            columnsLoaded,
          });
        },
      ),
      on(fromActions.loadFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),
      on(
        fromActions.addSplittedResponse,
        (
          state,
          {
            stats,
            translations: { total },
            reservation_date_from,
            reservation_date_to,
          },
        ) => {
          if (state.filtersHeaderValues.type !== 'date') {
            return { ...state };
          }
          const collected =
            state.filtersHeaderValues.partitioning === 'daily'
              ? this.statsTotalsHelper.sumCollectedDaily(
                  state?.stats?.totals?.collected,
                  stats?.values,
                  'production',
                )
              : this.statsTotalsHelper.sumCollected(
                  state?.stats?.totals?.collected,
                  stats?.values[0]?.collected,
                  'production',
                );

          const totals = {
            ...(stats.totals || ({} as StatsTotals)),
            collected,
          };

          const newStats = this.statsHelper.setAbsoluteGuestTotals(state, {
            dictionary: {
              ...state.stats.dictionary,
              ...stats.dictionary,
            },
            values: this.statsTotalsHelper.recalculateSharePercentForAllValues(
              [...(state.stats.values || []), ...stats.values],
              totals,
              'gross_amount',
            ),
            totals,
          });

          const statsList = this.statsHelper.flatDataArray(
            omit(newStats, 'totals'),
            total,
            reservation_date_from,
            reservation_date_to,
          );

          const {
            chart: { chartTypeSelected },
          } = state;

          const columnsLoaded = uniq([
            ...Object.keys(statsList[0].collected),
            chartTypeSelected,
          ]) as CollectedType[];

          return fromState.featureAdapter.upsertMany(statsList, {
            ...state,
            isLoading: false,
            error: null,
            stats: newStats,
            columnsLoaded,
            totalsRow: this.statsHelper.getTotalsRow(
              newStats,
              total,
              reservation_date_from,
              reservation_date_to,
            ),
          });
        },
      ),
      on(fromActions.exportRequest, (state) => ({
        ...state,
        isLoading: true,
        error: null,
      })),
      on(fromActions.exportSuccess, (state, { exportId }) => ({
        ...state,
        exportId,
        isLoading: false,
        error: null,
      })),
      on(fromActions.exportFailure, (state, { error }) => ({
        ...state,
        isLoading: false,
        error,
      })),

      on(
        fromActions.setFiltersHeaderValues,
        (state, { filtersHeaderValues }) => {
          return {
            ...state,
            filtersHeaderValues,
          };
        },
      ),
      on(fromActions.setChartTypeSelected, (state, { chartTypeSelected }) => {
        return {
          ...state,
          chart: {
            chartTypeSelected,
          },
        };
      }),
      on(
        fromActions.buildFiltersOptions,
        (
          state,
          {
            filtersHeaderValues: newFiltersHeaderValues,
            setInHeaderFiltersValue,
          },
        ) => {
          const {
            propertiesSelectedIds,
            filtersHeaderValues: oldFiltersHeaderValues,
            properties,
          } = state;

          const {
            reservationReasons,
            channels,
            accommodations,
            ratePlans,
            customerCategories,
            reservationFroms,
            propertiesTreatments,
            propertiesAddons,
          } = state?.filtersOptionsByProperties || {};

          const filtersHeaderValues = {
            ...oldFiltersHeaderValues,
            ...newFiltersHeaderValues,
          };

          const filtersOptions: StatsFiltersOptions = {
            reservationReasons:
              this.statsHelper.onSelectReservationReasonsOptionsBySelectedProperties(
                reservationReasons || [],
                propertiesSelectedIds,
              ),
            addons: this.statsHelper.onSelectAddonsOptionsBySelectedProperties(
              propertiesAddons || [],
              propertiesSelectedIds,
            ),
            treatment:
              this.statsHelper.onSelectTreatmentOptionsBySelectedProperties(
                propertiesTreatments || {},
                propertiesSelectedIds,
              ),
            channels:
              this.statsHelper.onSelectChannelsOptionsBySelectedProperties(
                channels || [],
                propertiesSelectedIds,
              ),
            accommodations:
              this.statsHelper.onSelectAccommodationsOptionsBySelectedProperties(
                accommodations || {},
                propertiesSelectedIds,
              ),

            ratePlans:
              this.statsHelper.onSelectRateplansOptionsBySelectedProperties(
                ratePlans || [],
                propertiesSelectedIds,
                [
                  {
                    id: null,
                    name: this.translateService.instant('deleted_rates'),
                    property_id: 'all_properties',
                    orderGroup: -1,
                  } as StatsRateplanFilterOption,
                ],
              ),
            customerCategories: customerCategories || [],
            reservationFroms: reservationFroms || [],
          };
          const {
            channels: newChannels,
            accommodations: newAccommodations,
            ratePlans: newRateplans,
            customerCategories: newCategories,
            reservationFroms: newReservationFrom,
            treatment: newTreatments,
            addons: newAddons,
          } = filtersOptions;
          const {
            reservation_channel: oldChannelsIds,
            accommodation: oldAccommodationsIds,
            daily_rateplan: oldRatePlansIds,
            booker_category: oldCategoriesIds,
            reservation_from: oldReservationFromIds,
            treatment: oldTreatmentIds,
            addon: oldAddonIds,
          } = filtersHeaderValues.filters;
          const isAllProperties =
            properties?.length === propertiesSelectedIds?.length;
          const newChannelsIds = (newChannels || []).map(
            (channel) => channel.id,
          );

          const newAddonsIds = newAddons.map((addon) => addon.id);

          const newTreatmentIds = newTreatments.map(
            (treatment) => treatment.treatment_id,
          );
          const newAccommodationsIds = newAccommodations.map(
            (accommodation) => accommodation.id,
          );
          const newRateplansIds = (newRateplans || []).map(
            (rateplan) => rateplan.id,
          );
          const newCategoriesIds = (newCategories || []).map(
            (category) => category.id,
          );
          const newReservationFromIds = (newReservationFrom || []).map(
            (reservation) => reservation.id,
          );
          const filters = {
            reservation_channel: [],
            addon: [],
            treatment: [],
            accommodation: [],
            daily_rateplan: [],
            booker_category: [],
            reservation_from: [],
            reservation_reason: [],
          };

          let values = filtersHeaderValues;

          if (setInHeaderFiltersValue) {
            values = {
              ...values,
              filters,
            };
          }

          return {
            ...state,
            filtersOptions,
            filtersHeaderValues: values,
            error: null,
          };
        },
      ),
      on(fromActions.setEnableValueChangeFilters, (state) => {
        return {
          ...state,
          enableValueChangeFilters: true,
        };
      }),

      on(fromActions.loadMinDateSuccess, (state, { min_date }) => {
        return {
          ...state,
          min_date: new Date(min_date),
        };
      }),

      on(
        fromActions.buildComparePeriodOptions,
        (state, { min_date: minDate }) => {
          const {
            filtersHeaderValues: { dates, pickup },
            comparePeriod: { defaultPreset: oldDefaultPreset },
            min_date,
          } = state;
          const minPropertyActivationDate = minDate
            ? new Date(minDate)
            : min_date;
          const comparePeriodOptions =
            this.statsHelper.buildcomparePeriodOptions(
              minPropertyActivationDate,
            );

          let defaultPreset: StatsPreset[] =
            this.statsHelper.setBeginningPreset(
              oldDefaultPreset,
              minPropertyActivationDate,
            );

          defaultPreset = this.statsHelper.addLastYearPreset(
            defaultPreset,
            minPropertyActivationDate,
          );

          const startDateIsBeforeActivationDate = moment(
            new Date(dates[0]),
          ).isBefore(minPropertyActivationDate, 'days');

          const periodPreset = this.statsHelper
            .onBuildPreset(minPropertyActivationDate, defaultPreset, pickup)
            .sort((a, b) => a.order - b.order);

          const pickupPreset = this.statsHelper
            .onBuildPresetPickup(
              minPropertyActivationDate,
              dates[1],
              defaultPreset,
            )
            .sort((a, b) => a.order - b.order);

          const newDate = !startDateIsBeforeActivationDate
            ? dates
            : [minPropertyActivationDate, moment().endOf('years').toDate()];

          return {
            ...state,
            comparePeriod: {
              ...state.comparePeriod,
              comparePeriodOptions,
              minPropertyActivationDate,
              pickupPreset,
              periodPreset,
              defaultPreset,
            },
            filtersHeaderValues: {
              ...state.filtersHeaderValues,
              dates: newDate,
            },
          };
        },
      ),

      on(
        fromActions.SetAccommodationRowsInViewport,
        (state, { accommodationRowsInViewport }) => {
          const { accommodationRowsLoaded } = state.tableauNumbers;
          const accommodationRowsToLoad = difference(
            accommodationRowsInViewport,
            accommodationRowsLoaded,
          );

          return {
            ...state,
            tableauNumbers: {
              ...state.tableauNumbers,
              accommodationRowsInViewport,
              accommodationRowsToLoad,
              accommodationRowsLoaded: [
                ...accommodationRowsLoaded,
                ...accommodationRowsToLoad,
              ],
            },
          };
        },
      ),
      on(fromActions.setInterruptCalls, (state, { stop }) => {
        return {
          ...state,
          interruptCalls: stop,
        };
      }),

      on(fromActions.resetState, () => fromState.initialState),
    );
  }
}

export function statsProductionReducerFactory(reducer: StatsProductionReducer) {
  return reducer.createReducer();
}
